'use client'

import Image from 'next/image'
import { applyStylesIf, cx } from 'utils/cx'

import type { TitleImage } from '../../../../../../Home.type'

import animationStyles from '../../../../MotionAnimations.module.scss'
import styles from './HeroBannerShopTitleImage.module.scss'

interface HeroBannerShopTitleProps {
	titleImage: TitleImage
	mobileTitleImage: TitleImage
	isLargeOrGreater: boolean
	isImageHeightOverflowing: boolean
	maxHeight: number | null
}

export function HeroBannerShopTitleImage({
	titleImage,
	mobileTitleImage,
	isLargeOrGreater,
	isImageHeightOverflowing,
	maxHeight,
}: HeroBannerShopTitleProps) {
	const currentTitleImage = isLargeOrGreater ? titleImage : mobileTitleImage
	const limitHeight = Boolean(
		isImageHeightOverflowing && typeof maxHeight === 'number'
	)
	const imageWidth = {
		'--image-width': isLargeOrGreater
			? `${currentTitleImage?.desktopWidth}%`
			: `${currentTitleImage?.mobileWidth}%`,
		'--limit-height': limitHeight ? `${maxHeight}px` : '100%',
	} as React.CSSProperties

	return (
		<div
			className={cx(
				animationStyles.opacityFadeIn,
				styles.titleImageHeader,
				applyStylesIf(limitHeight, styles.limitHeaderHeight)
			)}
			style={imageWidth}
		>
			<Image
				aria-hidden
				src={currentTitleImage.url}
				alt={currentTitleImage.description}
				width={0}
				height={0}
				className={cx(
					styles.titleImage,
					applyStylesIf(limitHeight, styles.limitImageHeight)
				)}
			/>
		</div>
	)
}
