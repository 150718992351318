'use client'

import { Breakpoints } from 'fukku/styles/breakpoints'
import type { PointOfInterestImage } from 'landings/Home/Home.type'
import { pointOfInterestImageLoaderFactory } from 'landings/utils/pointOfInterestImageLoaderFactory'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import Image from 'next/image'

type FamilyBannerShopImageProps = {
	className?: string
	image: PointOfInterestImage
	priority?: boolean
}

const ASPECT_RATIO_SMALL_WIDTH = 5
const ASPECT_RATIO_SMALL_HEIGHT = 7
const ASPECT_RATIO_SMALL = ASPECT_RATIO_SMALL_WIDTH / ASPECT_RATIO_SMALL_HEIGHT

const ASPECT_RATIO_MEDIUM_WIDTH = 5
const ASPECT_RATIO_MEDIUM_HEIGHT = 7
const ASPECT_RATIO_MEDIUM =
	ASPECT_RATIO_MEDIUM_WIDTH / ASPECT_RATIO_MEDIUM_HEIGHT

const ASPECT_RATIO_LARGE = 1

const HALF_MINUS_THRESHOLD = 0.45

export function FamilyBannerShopImage({
	className,
	image,
	priority = false,
}: FamilyBannerShopImageProps) {
	const PERCENTAGE = 0.01
	const { isMobile } = useMasterData()

	const pointOfInterest = {
		x: image.width * image.pointOfInterest.percentageX * PERCENTAGE,
		y: image.height * image.pointOfInterest.percentageY * PERCENTAGE,
	}
	const familyBannerImageLoader = pointOfInterestImageLoaderFactory(
		pointOfInterest,
		(width) => {
			if (isMobile) {
				return width / ASPECT_RATIO_SMALL
			}
			if (width <= Breakpoints.medium * HALF_MINUS_THRESHOLD) {
				return width / ASPECT_RATIO_SMALL
			}
			if (width <= Breakpoints.large * HALF_MINUS_THRESHOLD) {
				return width / ASPECT_RATIO_MEDIUM
			}
			return width / ASPECT_RATIO_LARGE
		}
	)

	return (
		<Image
			className={className}
			fill
			alt={image.description ?? ''}
			priority={priority}
			src={image.url}
			style={{
				objectFit: 'cover',
				objectPosition: `${image.pointOfInterest.percentageX}% ${image.pointOfInterest.percentageY}%`,
			}}
			loader={familyBannerImageLoader}
			sizes='45vw'
			draggable={false}
		/>
	)
}
