import {
	type ResponsiveBehavior,
	type ResponsiveBehaviorSimple,
	getResponsiveBehavior,
	isResponsiveBehavior,
	isResponsiveBehaviorSimple,
} from '../../behaviors/behaviors'
import { useResponsive } from '../useResponsive/useResponsive'

const INVALID_BEHAVIOR = 'Invalid behavior'

/**
 * @throws {Error} Invalid behavior
 */
export function useResponsiveBehavior<T>(
	behavior: ResponsiveBehavior<T> | ResponsiveBehaviorSimple<T>
): T {
	const {
		isSmall,
		isMedium,
		isLarge,
		isLessThanLarge,
		isLargeOrGreater,
		isExtraLargeOrGreater,
	} = useResponsive()
	try {
		if (isResponsiveBehaviorSimple(behavior)) {
			if (isLessThanLarge) {
				return getResponsiveBehavior(behavior, 'lessThanLarge')
			}
			if (isLargeOrGreater) {
				return getResponsiveBehavior(behavior, 'largeOrGreater')
			}
		} else if (isResponsiveBehavior(behavior)) {
			if (isSmall) {
				return getResponsiveBehavior(behavior, 'small')
			}
			if (isMedium) {
				return getResponsiveBehavior(behavior, 'medium')
			}
			if (isLarge) {
				return getResponsiveBehavior(behavior, 'large')
			}
			if (isExtraLargeOrGreater) {
				return getResponsiveBehavior(behavior, 'xlarge')
			}
		} else {
			throw new Error(INVALID_BEHAVIOR)
		}
	} catch (e) {
		throw new Error(INVALID_BEHAVIOR)
	}
	return getResponsiveBehavior(behavior, 'default')
}
