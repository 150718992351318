import type { PropsWithChildren } from 'react'

export type CarouselDirection = 'FORWARD' | 'BACKWARD'

export const enum CarouselVariants {
	VARIANT1 = 'Variant1',
	VARIANT2 = 'Variant2',
	VARIANT3 = 'Variant3',
	VARIANT4 = 'Variant4',
}

export interface CarouselTitle {
	content: string | JSX.Element
	textStyle?: string
}

export type CarouselHeader = {
	title?: CarouselTitle
	subtitle?: string
	status?: string
	action?: CarouselAction
	variant?: CarouselVariants
}

export type CarouselProps = CarouselHeader & {
	id?: string
	dataTestId?: string
	className?: string
}

export type CarouselMeasures = {
	viewportWidth: number
	carouselWidth: number
	elementWidth: number
	numElements: number
	gap: number
	padding: number
}

export type CarouselItems = PropsWithChildren<{
	variant: CarouselVariants
	isDragging?: boolean
	onScroll?: (event: React.UIEvent<HTMLUListElement>) => void
}>

export type CarouselAction = CarouselActionLink | CarouselActionButton

type BaseCarouselAction = {
	content: string
}

type CarouselActionLink = BaseCarouselAction & {
	href: string
	onClick?: () => void
}

type CarouselActionButton = BaseCarouselAction & {
	href?: never
	onClick: () => void
}
