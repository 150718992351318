'use client'

import { SelectPromotion } from 'analytics/components/SelectPromotion/SelectPromotion'
import { ButtonLink } from 'fukku/Button/ButtonLink'
import { applyStylesIf, cx } from 'utils/cx'

import type { CtaShop } from '../../../../Home.type'

import styles from './PromotionalBannerCtas.module.scss'

interface PromotionalBannerCtas {
	ctas: CtaShop[]
	isLow: boolean
	isSingleCta: boolean
}

export const PromotionalBannerCtas = ({
	ctas,
	isLow,
	isSingleCta,
}: PromotionalBannerCtas) => {
	return (
		<div
			className={cx(
				styles.ctasWrapper,
				applyStylesIf(isLow, styles.low),
				applyStylesIf(isSingleCta, styles.singleCta)
			)}
		>
			{ctas.map((cta) => (
				<SelectPromotion promotion={cta.promotion} key={cta.ctaId}>
					<ButtonLink
						className={cx(styles.ctaButton, applyStylesIf(isLow, styles.low))}
						linkAttributes={{ ...cta.linkProps, href: cta.href }}
					>
						{cta.text}
					</ButtonLink>
				</SelectPromotion>
			))}
		</div>
	)
}
