import type { IconProps } from 'icons/types/Icon.types'
import React from 'react'
import { cx } from 'utils/cx'

import { BUTTON_VARIANT } from '../../Button.types'
import { ButtonBase } from '../../ButtonBase'

import styles from './ButtonIconFloating.module.scss'

interface FloatingProps extends React.ComponentProps<'button'> {
	variant?: BUTTON_VARIANT
	children: React.ReactElement<IconProps>
}

export function ButtonIconFloating(props: FloatingProps): React.ReactNode {
	const {
		variant = BUTTON_VARIANT.DEFAULT,
		className,
		children,
		...rest
	} = props

	return (
		<ButtonBase
			{...rest}
			className={cx(className, styles[variant], styles.noBorder)}
		>
			<div className={styles.innerBox}>{children}</div>
		</ButtonBase>
	)
}
