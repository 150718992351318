'use client'

import { Breakpoints } from 'fukku/styles/breakpoints'
import Image from 'next/image'

import { pointOfInterestImageLoaderFactory } from '../../../../../utils/pointOfInterestImageLoaderFactory'
import type { PointOfInterestImage } from '../../../../Home.type'

interface PromotionalBannerImageProps {
	image: PointOfInterestImage
	priority: boolean
}

const SMALL_ASPECT_RATIO = 1
const MEDIUM_HEIGHT = 5
const MEDIUM_WIDTH = 7
const MEDIUM_ASPECT_RATIO = MEDIUM_WIDTH / MEDIUM_HEIGHT
const LARGE_HEIGHT = 5
const LARGE_WIDTH = 7
const LARGE_ASPECT_RATIO = LARGE_WIDTH / LARGE_HEIGHT
const PERCENTAGE = 0.01

export const PromotionalBannerImage = ({
	image,
	priority,
}: PromotionalBannerImageProps) => {
	const pointOfInterest = {
		x: image.width * image.pointOfInterest.percentageX * PERCENTAGE,
		y: image.height * image.pointOfInterest.percentageY * PERCENTAGE,
	}

	const promotionalBannerImageLoader = pointOfInterestImageLoaderFactory(
		pointOfInterest,
		(width) => {
			if (width <= Breakpoints.medium) {
				return width / SMALL_ASPECT_RATIO
			}
			if (width <= Breakpoints.large) {
				return width / MEDIUM_ASPECT_RATIO
			}
			return width / LARGE_ASPECT_RATIO
		}
	)

	return (
		<Image
			alt=''
			fill
			aria-hidden
			src={image.url}
			priority={priority}
			style={{
				objectFit: 'cover',
				objectPosition: `${image.pointOfInterest.percentageX}% ${image.pointOfInterest.percentageY}%`,
			}}
			loader={promotionalBannerImageLoader}
			sizes='(max-width: 1023px) 100vw, 44vw'
		/>
	)
}
