'use client'

import { useRef } from 'react'
import { useResponsiveBehavior } from 'responsive/hooks/useResponsiveBehavior/useResponsiveBehavior'

import { BannerWrapper } from '../../../BannerWrapper/BannerWrapper'

const MLY_ANIMATION_URL = '/images/mangoLikesYou/mly-home-acquisition.mp4'

const SIZE_SMALL = 263
const SIZE_MEDIUM = 384
const SIZE_LARGE = 401

const animationResponsiveBehavior = {
	default: {
		width: SIZE_SMALL,
		height: SIZE_SMALL,
	},
	medium: {
		width: SIZE_MEDIUM,
		height: SIZE_MEDIUM,
	},
	large: {
		width: SIZE_LARGE,
		height: SIZE_LARGE,
	},
	xlarge: {
		width: SIZE_LARGE,
		height: SIZE_LARGE,
	},
}

export interface MangoLikesYouImageProps {
	className?: string
	altAnimationDesc: string
	isPrioritySlot: boolean
}

export function MangoLikesYouAnimation({
	className,
	altAnimationDesc,
	isPrioritySlot,
}: MangoLikesYouImageProps): React.ReactNode {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const { width, height } = useResponsiveBehavior<{
		width: number
		height: number
	}>(animationResponsiveBehavior)

	return (
		<BannerWrapper
			ref={wrapperRef}
			priority={isPrioritySlot}
			className={className}
		>
			<video
				width={width}
				height={height}
				aria-label={altAnimationDesc}
				autoPlay={true}
				playsInline={true}
				loop={true}
				muted={true}
			>
				<source src={MLY_ANIMATION_URL} type='video/mp4' />
				<track kind='captions' default />
			</video>
		</BannerWrapper>
	)
}
