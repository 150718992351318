'use client'

import { useHeaderHeight } from 'hooks/useHeaderHeight/useHeaderHeight'
import { useEffect, useRef, useState } from 'react'
import { cx } from 'utils/cx'

import { BannerWrapper } from '../../../../BannerWrapper/BannerWrapper'

import styles from './HeroBannerFullHeightWrapper.module.scss'

interface HeroBannerFullHeightWrapperProps {
	className?: string
	isPrioritySlot?: boolean
	children: React.ReactNode
}

export function HeroBannerFullHeightWrapper({
	className,
	children,
	isPrioritySlot,
}: HeroBannerFullHeightWrapperProps) {
	const wrapperRef = useRef<HTMLDivElement>(null)

	const { headerHeight } = useHeaderHeight()

	const [height, setHeight] = useState<React.CSSProperties>({
		height: `calc(100vh - ${headerHeight}px)`,
	})

	useEffect(() => {
		if (wrapperRef.current && isPrioritySlot) {
			const reduceHeight = getComputedStyle(
				wrapperRef.current?.ownerDocument.body
			)
				.getPropertyValue('--reduce-height')
				.trim()

			if (reduceHeight !== '') {
				const heroBannerHeight = {
					height: `calc(100vh - ${reduceHeight})`,
				}
				setHeight(heroBannerHeight)
			}
		}
	}, [wrapperRef.current, headerHeight])

	return (
		<BannerWrapper
			ref={wrapperRef}
			style={height}
			className={cx(styles.heroBannerFullHeightWrapper, className)}
			priority={isPrioritySlot}
		>
			{children}
		</BannerWrapper>
	)
}

export default HeroBannerFullHeightWrapper
