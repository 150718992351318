'use client'

import { useRef } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { useTextOverflow } from '../../../../../../../hooks/useTextOverflow/useTextOverflow'

import textStyles from '../../../../TextStyles.module.scss'
import styles from './HeroBannerShopSalesText.module.scss'
import texts from 'fukku/styles/texts.module.scss'

interface HeroBannerShopSalesTextProps {
	titleKey: string
	inverse: boolean
	isLargeOrGreater: boolean
	subtitleTopKey?: string
	discount?: string
	subtitleBottomGroup?: {
		titleKey: string
		subtitleKey: string
	}[]
	subtitleBottomKey?: string
}

export const HeroBannerShopSalesText = ({
	subtitleTopKey,
	titleKey,
	discount,
	subtitleBottomGroup,
	subtitleBottomKey,
	inverse,
	isLargeOrGreater,
}: HeroBannerShopSalesTextProps) => {
	const textClassNames = cx(
		styles.text,
		applyStylesIf(inverse, styles.inverse),
		applyStylesIf(inverse, textStyles.textShadow)
	)
	const titleRef = useRef(null)
	const isTextOverflowing = useTextOverflow(titleRef)

	return (
		<div className={styles.textWrapper}>
			{subtitleTopKey && (
				<div
					className={cx(
						textClassNames,
						isLargeOrGreater ? texts.bodyMRegular : texts.bodySRegular
					)}
				>
					{subtitleTopKey}
				</div>
			)}
			<div className={styles.titleDiscountWrapper}>
				{titleKey && (
					<h2
						ref={titleRef}
						className={cx(
							textClassNames,
							styles.title,
							applyStylesIf(isTextOverflowing, styles.breakWord)
						)}
					>
						{titleKey}
					</h2>
				)}
				{discount && (
					<div
						className={cx(
							textClassNames,
							isLargeOrGreater ? texts.promoTitleL : texts.promoTitleXL
						)}
					>
						{discount}
					</div>
				)}
			</div>
			{subtitleBottomGroup && (
				<ul className={styles.subtitleBottomGroupList}>
					{subtitleBottomGroup.map(
						({ titleKey: itemTitleKey, subtitleKey: itemSubtitleKey }) => (
							<li
								key={`${itemTitleKey}-${itemSubtitleKey}`}
								className={styles.subtitleBottomListItem}
							>
								<span
									className={cx(
										textClassNames,
										isLargeOrGreater
											? styles.subtitleBottomTitleL
											: texts.promoTitleXS
									)}
								>
									{itemTitleKey}
								</span>
								<span
									className={cx(
										textClassNames,
										isLargeOrGreater ? texts.bodyMRegular : texts.bodySRegular
									)}
								>
									{itemSubtitleKey}
								</span>
							</li>
						)
					)}
				</ul>
			)}
			{subtitleBottomKey && (
				<div
					className={cx(
						textClassNames,
						isLargeOrGreater ? texts.bodyMRegular : texts.bodySRegular
					)}
				>
					{subtitleBottomKey}
				</div>
			)}
		</div>
	)
}
